import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize
} from "@mui/material";
import VMSheader from './vmsheader';
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import { getAPI, patchAPI } from '../network';
import { formatDate } from '../utils';




export default function UpdateTask() {

    const [taskData, setTaskData] = useState([])
    const navigate = useNavigate()
    const [answeOneYes, setAnsweOneYes] = useState(false)
    const [answeOneNo, setAnsweOneNo] = useState(false)
    const [answeOneNone, setAnsweOneNone] = useState(false)

    const [answeTwoYes, setAnsweTwoYes] = useState(false)
    const [answeTwoNo, setAnsweTwoNo] = useState(false)
    const [answeTwoNone, setAnsweTwoNone] = useState(false)

    const [ansThreeDef, setAnsThreeDef] = useState(false)
    const [ansThreeCD, setAnsThreeCD] = useState(false)
    const [ansThreeNCD, setAnsThreeNCD] = useState(false)
    const [ansThreeNC, setAnsThreeNC] = useState(false)
    const [ansThreeNone, setAnsThreeNone] = useState(false)

    const [completed, setCompleted] = useState(false)
    const [pcompleted, setPcompleted] = useState(false)
    const [reschedule, setreshedule] = useState(false)
    const [onHoled, setonhold] = useState(false)
    const [ansNone, setNone] = useState(false)

    const [update, setUpdate] = useState({
        answerOne: '',
        answerTwo: '',
        answerThree: '',
        note: '',
        status: '',
        picture: ''
    })

    const { id } = useParams();

    const handleFileChange = (e) => {
        setUpdate((prev) => ({
            ...prev,
            picture: e.target.files[0],
        }));
    };

    useEffect(() => {
        getTask()
    }, [])

    console.log(update, "update+++++++++++++++++++++++++++++++++")

    const getTask = async () => {

        const data = await getAPI(`/visitor-tasks/${id}`)
        if (data) {
            setTaskData(data)
        }

    }

    const setAnsweryes = () => {
        setAnsweOneYes(curr => !curr);
        setAnsweOneNo(false);
        setAnsweOneNone(false);
    }
    const setansweTwo = () => {
        setAnsweOneYes(false);
        setAnsweOneNo(curr => !curr);
        setAnsweOneNone(false);
    }
    const setansweOneNone = () => {
        setAnsweOneYes(false);
        setAnsweOneNo(false);
        setAnsweOneNone(curr => !curr);
    }


    const setansweTwoyes = () => {
        setAnsweTwoYes(curr => !curr);
        setAnsweTwoNo(false);
        setAnsweTwoNone(false);
    }
    const setansweTwoNo = () => {
        setAnsweTwoYes(false);
        setAnsweTwoNo(curr => !curr);
        setAnsweTwoNone(false);
    }
    const setansweTwoNone = () => {
        setAnsweTwoYes(false);
        setAnsweTwoNo(false);
        setAnsweTwoNone(curr => !curr);
    }


    const setansweThreeDef = () => {
        setAnsThreeDef(curr => !curr);
        setAnsThreeCD(false);
        setAnsThreeNCD(false);
        setAnsThreeNC(false);
        setAnsThreeNone(false);
    }
    const setansThreeCD = () => {
        setAnsThreeDef(false);
        setAnsThreeCD(curr => !curr);
        setAnsThreeNCD(false);
        setAnsThreeNC(false);
        setAnsThreeNone(false);
    }
    const setansThreeNCD = () => {
        setAnsThreeDef(false);
        setAnsThreeCD(false);
        setAnsThreeNCD(curr => !curr);
        setAnsThreeNC(false);
        setAnsThreeNone(false);
    }
    const setansThreeNC = () => {
        setAnsThreeDef(false);
        setAnsThreeCD(false);
        setAnsThreeNCD(false);
        setAnsThreeNC(curr => !curr);
        setAnsThreeNone(false);
    }
    const setansThreeNone = () => {
        setAnsThreeDef(false);
        setAnsThreeCD(false);
        setAnsThreeNCD(false);
        setAnsThreeNC(false);
        setAnsThreeNone(curr => !curr);
    }


    const setcompleted = () => {
        setCompleted(curr => !curr);
        setPcompleted(false);
        setreshedule(false);
        setonhold(false);
        setNone(false);
    }
    const setpcompleted = () => {
        setCompleted(false);
        setPcompleted(curr => !curr);
        setreshedule(false);
        setonhold(false);
        setNone(false);
    }
    const setreschedule = () => {
        setCompleted(false);
        setPcompleted(false);
        setreshedule(curr => !curr);
        setonhold(false);
        setNone(false);
    }
    const setonholed = () => {
        setCompleted(false);
        setPcompleted(false);
        setreshedule(false);
        setonhold(curr => !curr);
        setNone(false);
    }
    const setAnsFNone = () => {
        setCompleted(false);
        setPcompleted(false);
        setreshedule(false);
        setonhold(false);
        setNone(curr => !curr);
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('injurySuffered', update.answerOne);
        formData.append('taskCompleted', update.answerTwo);
        formData.append('reportableIssue', update.answerThree);
        formData.append('status', update.status);
        formData.append('notes', update.note);
        formData.append('picture', update.picture);
        formData.append('pictureStatus', update.picture ? true : '');
       
        const data = await patchAPI(`/visitor-tasks/${id}`, formData)
        if (data) {
            navigate('/vms/task_&_Notify')
        }
    }





    return (
        <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>

                <VMSheader />
                <Typography className='title-font' component="h6" variant="h5" sx={{ margin: '20px', fontWeight: 800, fontFamily: 'Poppins' }}>Update Tasks</Typography>

                <Typography component='h6' varient='' className='visit'>Update the Task from field below</Typography>

                <Typography component="h6" variant="h5" sx={{ marginTop: '15px', width: '100%' }}>
                    <div className='cardA'>
                        <div className='mt'>
                            <p className='titlee para'>Task Name </p>
                            <p className='val para'>
                                {taskData?.title}
                            </p>
                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Task Description</p>
                            <p className='val para'>{taskData?.description}</p>

                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Company</p>
                            <p className='val para'>{taskData?.companyId?.name}</p>
                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Site</p>
                            <p className='val para'>{taskData?.siteId?.name}</p>

                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Due Date</p>
                            <p className='val para'>{formatDate(taskData?.endDate)}</p>

                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Due Time</p>
                            <p className='val para'>{taskData?.timeDue}</p>

                        </div>

                        <div className='mt'>
                            <p className='titlee para'>Vendor Name</p>
                            <p className='val para'>{taskData?.vendor}</p>

                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Service Category Level 1</p>
                            <p className='val para'>{taskData?.serviceL1}</p>
                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Service Category Level 2</p>
                            <p className='val para'>{taskData?.serviceL2}</p>
                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Service Category Level 3</p>
                            <p className='val para'>{taskData?.serviceL3}</p>
                        </div>
                        <div className='mt'>
                            <p className='titlee para'>Work Order Number </p>
                            <p className='val para'>{taskData?.workOrderNo}</p>

                        </div>
                        {/* <div >
                            <p className='titlee para'>Assigned to</p>
                            <p className='val para'>{taskData?.assignedUser?.name}</p>
                        </div> */}

                    </div>


                </Typography>




                <Typography>

                    <div>
                        <p className='ques'> Have you suffered any injury or near miss?</p>
                        <Button className='backbtn' value="Yes"
                            style={{
                                backgroundColor: answeOneYes ? '#42505C' : '',
                                color: answeOneYes ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerOne: e.target.value
                                }))
                                setAnsweryes()
                            }}>Yes</Button>

                        <Button className='backbtn' value="No"
                            style={{
                                backgroundColor: answeOneNo ? '#42505C' : '',
                                color: answeOneNo ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerOne: e.target.value
                                }))
                                setansweTwo()
                            }}>No</Button>

                        <Button className='backbtn' value='None'
                            style={{
                                backgroundColor: answeOneNone ? '#42505C' : '',
                                color: answeOneNone ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerOne: e.target.value
                                }))
                                setansweOneNone()
                            }}>None</Button>
                    </div>

                    <div>
                        <p className='ques'> Have you completed all the required tasks?</p>
                        <Button className='backbtn' value="Yes"
                            style={{
                                backgroundColor: answeTwoYes ? '#42505C' : '',
                                color: answeTwoYes ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerTwo: e.target.value
                                }))

                                setansweTwoyes()
                            }}

                        >Yes</Button>
                        < Button className='backbtn' value="No"
                            style={{
                                backgroundColor: answeTwoNo ? '#42505C' : '',
                                color: answeTwoNo ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerTwo: e.target.value
                                }))
                                setansweTwoNo()
                            }}
                        >No</Button>
                        < Button className='backbtn' value='None'
                            style={{
                                backgroundColor: answeTwoNone ? '#42505C' : '',
                                color: answeTwoNone ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerTwo: e.target.value
                                }))
                                setansweTwoNone()
                            }}>None</Button>
                    </div>


                    <div>
                        <p className='ques'>Is there a reportable issue? Select one from below</p>
                        <Button className='backbtn' value="Defect"
                            style={{
                                backgroundColor: ansThreeDef ? '#42505C' : '',
                                color: ansThreeDef ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerThree: e.target.value
                                }))
                                setansweThreeDef()
                            }}
                        >Defect</Button>

                        < Button className='backbtn' value='Critical Defect'
                            style={{
                                backgroundColor: ansThreeCD ? '#42505C' : '',
                                color: ansThreeCD ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerThree: e.target.value
                                }))
                                setansThreeCD()
                            }}
                        >Critical Defect</Button>
                        < Button className='backbtn' value='Non Critical Defect'
                            style={{
                                backgroundColor: ansThreeNCD ? '#42505C' : '',
                                color: ansThreeNCD ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerThree: e.target.value
                                }))
                                setansThreeNCD()
                            }}
                        >Non Critical Defect</Button>
                        < Button className='backbtn' value='Non Conformance'
                            style={{
                                backgroundColor: ansThreeNC ? '#42505C' : '',
                                color: ansThreeNC ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerThree: e.target.value
                                }))
                                setansThreeNC()
                            }}
                        >Non Conformance</Button>
                        < Button className='backbtn' value='None'
                            style={{
                                backgroundColor: ansThreeNone ? '#42505C' : '',
                                color: ansThreeNone ? 'white' : '',
                            }}

                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    answerThree: e.target.value
                                }))
                                setansThreeNone()
                            }}
                        >None</Button>
                    </div>


                    <div>
                        <p className='ques'>Update Task Status</p>
                        <Button className='backbtn' value='Completed'
                            style={{
                                backgroundColor: completed ? '#42505C' : '',
                                color: completed ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    status: e.target.value
                                }))
                                setcompleted()
                            }}
                        >Completed</Button>
                        < Button className='backbtn' value='Partially Completed'
                            style={{
                                backgroundColor: pcompleted ? '#42505C' : '',
                                color: pcompleted ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    status: e.target.value
                                }))
                                setpcompleted()
                            }}
                        >Partially Completed</Button>

                        < Button className='backbtn' value='Reschedule'
                            style={{
                                backgroundColor: reschedule ? '#42505C' : '',
                                color: reschedule ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    status: e.target.value
                                }))
                                setreschedule()
                            }}
                        >Reschedule</Button>

                        < Button className='backbtn' value='On Hold'
                            style={{
                                backgroundColor: onHoled ? '#42505C' : '',
                                color: onHoled ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    status: e.target.value
                                }))
                                setonholed()
                            }}
                        >On Hold</Button>

                        < Button className='backbtn' value='None'
                            style={{
                                backgroundColor: ansNone ? '#42505C' : '',
                                color: ansNone ? 'white' : '',
                            }}
                            onClick={(e) => {
                                setUpdate(prevState => ({
                                    ...prevState,
                                    status: e.target.value
                                }))
                                setAnsFNone()
                            }}
                        >None</Button>
                    </div>
                    <div className='cardA shadow' >
                        <Typography component='h6' varient='' className='txt'>Note</Typography>
                        <TextareaAutosize
                            size="md"
                            name="Size"
                            maxRows={30}
                            aria-label="Note"
                            minRows={3}
                            minColumns={6}
                            style={{ minWidth: '90%', fontSize: 12, padding: 5, fontFamily: "Poppins,Helvetica,Arial" }}
                            fullWidth
                            placeholder="Task completion notes including Defect, Injury etc."
                            onChange={(e) => {
                                setUpdate(prev => ({
                                    ...prev,
                                    note: e.target.value
                                }))
                            }} />

                    </div>
                    <div className='cardB' >

                        <input type="file" onChange={handleFileChange} />
                    </div>

                    <div><Button className='submitBtn' onClick={(e) => handleSubmit(e)} >Submit</Button></div>

                </Typography>



            </Box>
        </Grid>
    )
}

