/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, TextField, Typography, Link } from "@mui/material";

import Logo from "../../assets/images/logo.png"
import VMSheader from './vmsheader';

import './style.css'
import { getAPI } from "../network";
import {formatDate, timeFormat } from "../utils";




export default function History() {
    const [history,setHistory]=useState([])

    useEffect(()=>{
        getHistory()
    },[])
    const getHistory=async()=>{
        const data=await getAPI('/visitor/book/history')
        if(data){
            setHistory(data)
        } 
    }


    return (

        <>


            <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>
                <VMSheader />

                    <Typography className='title-font' component="h6" variant="h5" sx={{ margin: '20px' ,fontWeight:800 , fontFamily:'Poppins'}}>My History</Typography>
                    <Typography sx={{ width: '100%' }}>
                        <div className="names">
                            <div>Check In Date & Time</div>
                            <div>Site Name</div>
                        </div>
                    </Typography>
                    {
                        history.map((item)=>(

                            <Typography component="h6" variant="h5" sx={{ marginTop: '30px',width: '100%'}}>
                            <div className='cardA'>
                                <div className="fex">
                                    <div><p>{formatDate(item.visitDate)}  ({timeFormat(item.checkIn)})</p></div>
                                    <div>{item?.siteId?.name}</div>
                                </div>
                                <hr></hr>
                            </div>
                        </Typography>

                        ))
                    }
                   

                </Box>
            </Grid>








        </>


    )
}

