/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, TextField, Typography, Link } from "@mui/material";
import VMSheader from './vmsheader';

import Logo from "../../assets/images/logo.png"
import { useNavigate } from "react-router-dom";
import { formatDate, timeFormat } from '../utils';
import './style.css'
import { getAPI } from "../network";



export default function Checkout() {
    const [checkin, setCheckin] = useState({})
    const [checkInDetails, setCheckInDetails] = useState({})
    const [checkOutTime, setCheckoutTime] = useState(new Date().toISOString())
    const navigateTo = useNavigate();
    const email = localStorage.getItem('email');

    const handleNavigate = () => {
        navigateTo(`/vms/complete_checkout`);
    }
    useEffect(() => {
        getCheckOut()
    }, [])

    const getCheckOut = async () => { 

        const data = await getAPI(`/visitor/book/singleFetch/${email}`)
        if (data) {
            setCheckInDetails(data)
        }

    }

    function calculateTimeSpent(checkInTime, checkOutTime) {
        const checkIn = new Date(checkInTime);
        const checkOut = new Date(checkOutTime);

        const timeDifference = checkOut - checkIn;
        // const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

        return `${hours} hours, ${minutes} minutes`;
    }






    return (

        <>


            <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>

                    <VMSheader />
                    <Typography className='title-font' component="h6" variant="h5" >Check Out</Typography>

                    <Typography component="h6" variant="h5" sx={{ marginTop: '30px', width: '100%' }}>
                        <div className='cardC'>
                            <div className="fex2">
                                <div>Arrival Date :</div>
                                <div>{formatDate(checkInDetails?.checkIn)}</div>
                            </div>

                            <div className="fex2">
                                <div>Arrival Time :</div>
                                <div>{timeFormat(checkInDetails?.checkIn)}</div>
                            </div>

                            <div className="fex2">
                                <div>Time on Site :</div>
                                {/* <div>{timeFormat(checkInDetails?.visitDate)}</div> */}
                                <div>{calculateTimeSpent(checkInDetails?.checkIn, checkOutTime)}</div>
                            </div>
                        </div>
                    </Typography>
                    <Typography sx={{ marginTop: '50px', width: '100%' }}>
                        <div><Button className="btnCheckOut" onClick={handleNavigate}>Check Out</Button></div>
                    </Typography>

                </Box>
            </Grid>








        </>


    )
}

