import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    FormControl, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import VMSheader from './vmsheader';
import './style.css'
import { useNavigate } from "react-router-dom";
import { patchAPI } from '../network';
import { toast } from "react-toastify";


// const useStyles = makeStyles(() => ({
//   inputRoot: {
//     borderRadius: "10px !important",
//     backgroundColor: "white",
//     padding: "0 5px",
//     boxShadow:
//       "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
//     "& .MuiOutlinedInput-input": {
//       padding: "14px !important",
//     },
//   },
//   buttoRoot: {
//     borderColor: "#707070 !important;",
//     color: "#202E43 !important;",
//     borderRadius: "8px !important;",
//     fontSize: "16px  !important;",
//     textTransform: "none !important;",
//     padding: "0px 30px !important;",
//     margin: "5px !important",
//     marginRight: "15px !important;",
//     "&:hover": {
//       backgroundColor: " #42505C !important;",
//       color: "white !important",

//     },
//   },
// }));

const bookId = JSON.parse(localStorage.getItem("BookId"));




export default function Visit() {
    const email = localStorage.getItem('email');
    const [reason, setReason] = useState('')
    const [visitorType, setVisitorType] = useState('')
    const [hostEmail, setHostEmail] = useState('')


    const navigateTo = useNavigate();

    useEffect(() => {

    }, [])



    const handleNavigate = () => {
        if (!visitorType) {
            toast.warning('Please Choose Visitor Type')
            return
        }
        if (!reason) {
            toast.warning('Please Choose Reason to Visit')
            return
        }
        const payload = {
            visitorType: visitorType,
            purpose: reason,
            bookId: bookId,
            hostEmail: hostEmail,
            email: email,
        }
        const data = patchAPI('/visitor/profile/updatePurpose', payload)

        if (data) {
            navigateTo(`/vms/task_&_Notify`);
        }

    }




    return (
        <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>

                <VMSheader />

                <Typography component="h6" variant="h5" className="title-font" sx={{ margin: '20px', fontWeight: 600, fontFamily: 'Poppins' }}>Reason for Visit</Typography>

                <Typography component='h6' varient='' className='visit'>Please select Visitor Type</Typography>

                <FormControl fullWidth className='m-20'>
                    <InputLabel id="demo-simple-select-label">Visitor Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={visitorType}
                        label="Visitor Type"
                        onChange={(e) => {
                            setVisitorType(e.target.value)
                        }}
                        sx={{ borderRadius: '10px', boxShadow: '0px 2px 6px rgba(66, 80, 92, 0.3)' }}

                    >
                        <MenuItem value={'Guest'}>Guest</MenuItem>
                        <MenuItem value={'Contractor'}>Contractor</MenuItem>


                    </Select>
                </FormControl>
                <Typography component='h6' varient='' className='visit'>Please select the reason for your Visit</Typography>
                {
                    visitorType === 'Guest' ?
                        <FormControl fullWidth className='m-20'>
                            <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                label="Select Reason"
                                onChange={(e) => {
                                    setReason(e.target.value)
                                }}
                                sx={{ borderRadius: '10px', boxShadow: '0px 2px 6px rgba(66, 80, 92, 0.3)' }}

                            >

                                <MenuItem value={'Meeting'}>Meeting</MenuItem>
                                <MenuItem value={'Event'}>Event</MenuItem>
                                <MenuItem value={'Business'}>Business</MenuItem>
                                <MenuItem value={'Deliveries'}>Deliveries</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>



                            </Select>
                        </FormControl>
                        :
                        null
                }
                {
                    visitorType === 'Contractor' ?
                        <FormControl fullWidth className='m-20'>
                            <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                label="Select Reason"
                                onChange={(e) => {
                                    setReason(e.target.value)
                                }}
                                sx={{ borderRadius: '10px', boxShadow: '0px 2px 6px rgba(66, 80, 92, 0.3)' }}

                            >
                                <MenuItem value={'Preventative Maintenance'}>Preventative Maintenance</MenuItem>
                                <MenuItem value={'Reactive Maintenance'}>Reactive Maintenance</MenuItem>
                                <MenuItem value={'Project'}>Project</MenuItem>
                                <MenuItem value={'Quoted Job'}>Quoted Job</MenuItem>
                                <MenuItem value={'Others'}>Others</MenuItem>


                            </Select>
                        </FormControl>
                        :
                        null
                }
                {
                    !visitorType ?
                        <FormControl fullWidth className='m-20'>
                            <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                label="Select Reason"
                                onChange={(e) => {
                                    setReason(e.target.value)
                                }}
                                sx={{ borderRadius: '10px', boxShadow: '0px 2px 6px rgba(66, 80, 92, 0.3)' }}

                            >
                                <MenuItem value={null}>Please Choose Visitor Type</MenuItem>

                            </Select>
                        </FormControl>
                        :
                        null
                }


                <Typography component="h6" variant="h5" className="title-font" sx={{ margin: '20px', fontWeight: 600, fontFamily: 'Poppins' }}>Notify Host</Typography>

                <Typography component='h6' varient='' className='visit'>Please enter the Host email to notify them about your visit</Typography>

                <TextField id="email" label="Host Email" className='hund' sx={{ borderRadius: '10px', marginBottom: '20px' }} variant="outlined" onChange={(e) => setHostEmail(e.target.value)} value={hostEmail} />


                <Button variant="outlined" className='buttoRootA'
                    onClick={handleNavigate}>
                    Notify Host
                </Button>



            </Box>
        </Grid>
    )
}

