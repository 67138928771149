import React, { useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoDevIcon from "@mui/icons-material/LogoDev";
import Logo from "../../assets/images/logo.png"
import { useNavigate } from "react-router-dom";



const pages = ["Home", "Products", "Blog", "About US"];

function Vmsheader() {
  const navigateTo = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu1 = () => {
    navigateTo(`/vms/profile`);
    setAnchorElNav(null);
  };
  const handleCloseNavMenu2 = () => {
    navigateTo(`/vms/induction`);
    setAnchorElNav(null);
  };
  const handleCloseNavMenu3 = () => {
    navigateTo(`/vms/task_&_Notify`);
    setAnchorElNav(null);
  };
  const handleCloseNavMenu4 = () => {
    navigateTo(`/vms/history`);
    setAnchorElNav(null);
  };
  const handleCloseNavMenu5 = () => {

    setAnchorElNav(null);
  };
  const handleCloseNavMenu6 = () => {

    setAnchorElNav(null);
  };
  const handleCloseNavMenu7 = () => {
    navigateTo(`/vms/checkout`);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box> */}

          <Box sx={{ flexGrow: 0 }}>
            <img width='50px' height='50px' src={Logo}></img>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              // onClose={handleCloseNavMenu1}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
             
                <MenuItem  onClick={handleCloseNavMenu1}>
                  <Typography className="titlee" >Profile</Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu2}>
                  <Typography  className="titlee" >Inductions</Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu3}>
                  <Typography   className="titlee">Tasks</Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu4}>
                  <Typography  className="titlee">My History</Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu5}>
                  <Typography  className="titlee">Terms & Conditions</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu6}>
                  <Typography  className="titlee">Privacy Policy</Typography>
                </MenuItem>
                <MenuItem  onClick={handleCloseNavMenu7}>
                  <Typography  className="titlee">Check Out</Typography>
                </MenuItem>
              
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Vmsheader;