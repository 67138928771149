import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  FormControl,
  Typography,
  IconButton,
  Toolbar, AppBar
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import VMSheader from './vmsheader';
import Logo from "../../assets/images/logo.png"
import './style.css'
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAPI, postAPI } from '../network';
import { formatDate } from '../utils';
import { toast } from "react-toastify";
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    padding: '16px',
    '@media (max-width: 600px)': {
      padding: '8px',
    },
  },
  mediaContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
    },
  },
  media: {
    width: '100%',
    height: '100%',
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
    },
  },
  thumbnail: {
    width: '100px',
    height: '100px',
    '@media (max-width: 600px)': {
      width: '75px',
      height: '75px',
    },
  },
}));

export default function Induction() {
  const toastObj = { position: toast.POSITION.TOP_RIGHT };
  const bookId = JSON.parse(localStorage.getItem('BookId'));
  const email = localStorage.getItem('email');
  const [checked, setChecked] = useState(false)
  const [documents, setDocuments] = useState([])
  const currDate = new Date().toJSON()
  const siteId = localStorage.getItem('siteId');
  const navigateTo = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);


  const handleOpen = (media) => {
    setSelectedMedia(media);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
  };
  const handleInduction = async () => {

    if (!checked) {
      toast.warning('Confirmation is required', toastObj)
      return;
    }

    const payload = {
      email,
      induction: true,
    }
    const data = await postAPI('visitor/book/updateinduction', payload)

    if (data) {
      navigateTo(`/vms/visit`);
    }

  }
  const getSiteDocument = async () => {

    let data = await getAPI(`/user/site-specific-induction/vms?siteId=${siteId}&visibilityPortal=${true}`)
    if (data) {
      setDocuments(data)
    }

  }

  useEffect(() => {
    getSiteDocument()
  }, [])
  return (
    <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>

        {/* <div className='fex2'>
            <div><img height='80px' width='80px' src={Logo}></img></div>
            <div>bar</div>
          </div> */}

        <VMSheader />

        <div className="title-font" >Induction</div>

        <Typography component='h6' varient='' className='visit'>Please read below document & Proceed</Typography>


        {
          documents.map((i, index) => (
            <Button variant="outlined" className='buttoRootA' key={index} onClick={() => handleOpen(i?.document)}>
              {i?.title} ( {i?.category} )
            </Button>
          ))
        }


        <Typography component='h6' varient='' className='visit'>Your next Induction due is on {formatDate(currDate)}</Typography>

        <Typography component='h6' varient='' className='confirm'><input type='checkbox' onClick={() => setChecked(!checked)}></input>I confirm I have read and/or have access to above documents</Typography>

        <Button variant="outlined" className='buttoRootA mar' onClick={handleInduction}>
          Proceed
        </Button>

      </Box>
      <div>

        {selectedMedia && (
          <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
          >
            <Box className={classes.mediaContainer}>
              {selectedMedia?.type === 'image' ? (
                <img src={selectedMedia} alt="Media" className={classes.media} />
              ) : (
                <embed src={selectedMedia} type="application/pdf" className={classes.media} />
              )}
            </Box>
          </Modal>
        )}
      </div>
    </Grid>
  )
}

