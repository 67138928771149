import React from 'react';


const NotFound = () => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '100vh',
            // textAlign: 'center',
        },
        heading: {
            fontSize: '3em',
            margin: '20px 0',
            color: '#ff0000',
        },
        paragraph: {
            margin: '20px 0',
        }
    };

    return (
        <div style={styles.container}>
            <p style={styles.paragraph}>Please, Contact to admin</p>
            <h1 style={styles.heading}>404 - Page Not Found or Link Expired</h1>
            <p style={styles.paragraph}>Sorry, the page you are looking for does not exist.</p>

        </div>
    );
};

export default NotFound;
