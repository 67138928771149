// SignaturePad.js
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './SignaturePad.css';

const SignaturePad = ({ onSave }) => {
  const sigCanvas = useRef({});

  // Clear the signature pad
  const clear = () => {
    sigCanvas.current.clear();
  };

  // Save the signature as an image and call the onSave prop function
  const save = () => {
    sigCanvas.current.getCanvas().toBlob((blob) => {
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      onSave(file);
    });
  };

  return (
    <div className="signature-pad">
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{ className: 'sigCanvas' }}
      />
      <div className="buttons">
        <button onClick={clear}>Clear</button>
        <button onClick={save}>Save</button>
      </div>
    </div>
  );
};

export default SignaturePad;
