import { useState } from 'react';
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
} from "@mui/material";
import Logo from "../../assets/images/logo.png"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@mui/styles";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { patchAPI, getAPI } from '../network';

const theme = createTheme();

theme.typography.cmt = {
  fontSize: '14px',
  textAlign: 'center',
  fontWeight: '700',
  color: '#96979C',
  fontFamily: 'Poppins',
  '@media (min-width:600px)': {
    fontSize: '14px',
    fontWeight: '700',
    color: '#96979C',
    fontFamily: 'Poppins',

  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
};

theme.typography.h2 = {
  backgroundColor: '#457B9D',
  color: 'white',
  fontSize: '14px',
  padding: '10px',
  borderRadius: '8px',
  fontWeight: 800,
  marginTop: '20px',

  '@media (min-width:600px)': {
    fontSize: '13px',
  },
}

// const useStyles = makeStyles(() => ({
//   inputRoot: {
//     borderRadius: "10px !important",
//     backgroundColor: "white",
//     padding: "0 5px",
//     boxShadow:
//       "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
//     "& .MuiOutlinedInput-input": {
//       padding: "14px !important",
//     },
//   },
//   buttoRoot: {
//     borderColor: "#707070 !important;",
//     color: "#202E43 !important;",
//     borderRadius: "8px !important;",
//     fontSize: "16px  !important;",
//     textTransform: "none !important;",
//     padding: "0px 30px !important;",
//     margin: "5px !important",
//     marginRight: "15px !important;",
//     "&:hover": {
//       backgroundColor: " #42505C !important;",
//       color: "white !important",

//     },
//   },
// }));




function Profile() {
  const navigateTo = useNavigate();
  const [pro, setPro] = useState({})
  const [visitor, setVisitor] = useState({})
  const siteId = localStorage.getItem('siteId');

  const [profile, setProfile] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    BName: '',
    position: '',
    lat: '',
    lng: '',

  })
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setProfile(prevState => ({
          ...prevState,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }))
       
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };
  useEffect(() => {
    const visitors = localStorage.getItem("visitor");
    if (visitors) {
      setVisitor(JSON.parse(visitors))
    }
    getProfile()
    getLocation()
  }, [])

  const handleNavigate = () => {
    if (!profile.firstname) {
      toast.error('Please Enter Valid First Name');
    }
    if (!profile.lastname) {
      toast.error('Please Enter Valid Last Name');
    }
    else if (!profile.email) {
      toast.error('Please Enter Valid Email');
    }
    else if (!profile.phone) {
      toast.error('Please Enter Valid Phone');
    }
    else if (!profile.BName) {
      toast.error('Please Enter Valid Business Name');
    }
    else if (!profile.position) {
      toast.error('Please Enter Valid Role');
    } else {
      navigateTo(`/vms/induction`);
    }

  }

  const getProfile = async () => {

    let data = await getAPI('/visitor/profile/')
    if (data) {
      setPro(data)
      setProfile(prev => ({
        ...prev,
        firstname: data?.firstname,
        lastname: data?.lastname,
        email: data?.email,
        phone: data?.phone?.toString(),
        BName: data?.business,
        position: data?.role,

      }))
      localStorage.setItem('email', data.email);
      localStorage.setItem('userId', data.id);

    }

  }


  const updateProfile = async () => {


    let payload = {
      firstname: profile.firstname,
      lastname: profile?.lastname,
      email: profile.email,
      phone: profile.phone,
      role: profile.position,
      business: profile.BName,
      siteId: siteId,
      lat: profile.lat,
      lng: profile.lng,

    }


    await patchAPI(`/visitor/profile`, payload)
    localStorage.setItem('email', profile.email);

  }

  return (
    <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>
        <img width='180px' height='180px' src={Logo} alt='empty'></img>

        <ThemeProvider theme={theme}>
          <Typography component="h6" variant="h5" className="title-font">Your Profile</Typography>

          <Typography component='h6' varient='' className='visit'>Please enter details below to complete your profile</Typography>

          <TextField className='hund box' id="outlined-basic" label="First Name" variant="outlined" value={profile.firstname}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                firstname: data.target.value,
              }))
            }}
          />
          <TextField className='hund box' id="outlined-basic" label="Last Name" variant="outlined" value={profile.lastname}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                lastname: data.target.value,
              }))
            }}
          />

          <TextField className='hund box' id="outlined-basic" label="Email" variant="outlined" value={profile.email}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                email: data.target.value,
              }))
            }}
            disabled
          />
          <TextField className='hund box' id="outlined-basic" label="Phone" variant="outlined" value={profile.phone}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                phone: data.target.value,
              }))
            }} />
          <TextField className='hund box' id="outlined-basic" label="Business Name" variant="outlined" value={profile.BName}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                BName: data.target.value,
              }))
            }}
          />
          <TextField className='hund box' id="outlined-basic" label="Position" variant="outlined" value={profile.position}
            onChange={(data) => {
              setProfile(prevState => ({
                ...prevState,
                position: data.target.value, 
              }))
            }} />

          <Button variant="outlined" className='buttoRootA' onClick={updateProfile}>
            Update
          </Button>
          {
            profile.firstname&&profile.lastname && profile.email && profile.phone && profile.BName && profile.position ? <Button variant="outlined" className='buttoRootA' onClick={handleNavigate}>
              Proceed
            </Button>
              :
              null
          }


        </ThemeProvider>

      </Box>
    </Grid>
  )
}

export default Profile