import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { patchAPI, postAPI } from '../network';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TextField, MenuItem, Button, Typography, Box, CircularProgress, Card, CardContent } from '@mui/material';
import { toast } from "react-toastify";
import SignaturePad from './SignaturePad';


const theme = createTheme();

theme.typography.h5 = {
  fontSize: '18px',
  textAlign: 'center',
  '@media (min-width:600px)': {
    fontSize: '14px',
    fontWeight: 900,

  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
};

theme.typography.h6 = {
  backgroundColor: '#457B9D',
  color: 'white',
  fontSize: '13px',
  padding: '10px',
  borderRadius: '8px',
  fontWeight: 800,
  marginTop: '20px',
  // height: '220px',

  '@media (min-width:600px)': {
    fontSize: '10px',
  },
}
theme.typography.subtitle2 = {
  padding: '10px'
}


const AlarmResponse = () => {
  const { token } = useParams(); // Retrieve the 'token' parameter from the URL
  const [alarmData, setAlarmData] = useState(null);
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    status: '',
    notes: '',
    lat: '',
    lng: '',
    time: '',
    startLat: '',
    startLng: '',
    startTime: '',
    distanceCovered: '',
    timeTaken: '',
    picture: null,
    signature: null,
  });

  const [progress, setProgress] = useState({
    picture: 0,
    signature: 0,
  });

  const [previews, setPreviews] = useState({
    picture: null,
    signature: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prev) => ({ ...prev, [name]: reader.result }));
      };
      reader.readAsDataURL(file);
      setFormData((prev) => ({ ...prev, [name]: file }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = () => {
    setLoader(true)
    getEndLocation()
    calculateTime()
    setTimeout(() => {
      setLoader(false)
      setSubmit(true)
    }, 2000);
  }

  const handleSubmit = async () => {

    const formDataToSend = new FormData();
    formDataToSend.append('status', formData.status);
    formDataToSend.append('lat', formData.lat);
    formDataToSend.append('lng', formData.lng);
    formDataToSend.append('startLat', formData.startLat);
    formDataToSend.append('startLng', formData.startLng);
    formDataToSend.append('distanceCovered', formData.distanceCovered);
    formDataToSend.append('timeTaken', formData.timeTaken);
    formDataToSend.append('notes', formData.notes);
    formDataToSend.append('picture', formData.picture);
    formDataToSend.append('signature', formData.signature);

    try {
      console.log(formData)
      if (!formData.signature) {
        return toast.error(`Please Do Your Signature`);
      }
      if (!formData.status) {
        return toast.error(`Please Select Status`);
      }
      if (!formData.lat) {
        toast.error(`Please Allow Location access`);
        getEndLocation()
        return
      }
      const response = await patchAPI(`/alarm/update/${alarmData?._id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }, 
        onUploadProgress: (progressEvent) => {
          setProgress({
            picture: Math.round((progressEvent.loaded / progressEvent.total) * 100),
          });
        },
      });
      if (response.message === 'Alarm responded successfully.') {
        setLoader(false)
        navigate('/')
      }
      // Handle success
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error
    }

  };
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await postAPI(`/alarm/validation/${token}`);
        if (response.data) {
          setAlarmData(response.data);
          getStartLocation()
          console.log(1)
        } else {
          navigate('/invalidLink/CheckWithAdmin')
        }
      } catch (error) {
        setError('Invalid or expired link');
      }
    };

    validateToken();
  }, [token]);

  //! Location Feature -----------------------------------------------------

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getStartLocation = () => {
    if (!formData.startLat) {
      setIsModalOpen(true)
    }
  };



  const handleLocationAccess = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prevState => ({
            ...prevState,
            startLat: position.coords.latitude,
            startLng: position.coords.longitude,
            startTime: new Date(),
          }))
          setIsModalOpen(false);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
          setIsModalOpen(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsModalOpen(false);
    }
  };

  const getEndLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormData(prevState => ({
          ...prevState,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          time: new Date(),
        }))
        calculateDistance(position.coords.latitude, position.coords.longitude)
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
    console.log('getEndLocation')
  };

  const calculateTime = (t1) => {
    const time1 = new Date(formData.startTime);
    const time2 = new Date();

    const differenceInMilliseconds = time2.getTime() - time1.getTime();

    // Convert the difference to various units
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    setFormData(prevState => ({
      ...prevState,
      timeTaken: differenceInMinutes,
    }))
    console.log("calculateTime", time2.getTime(), time1.getTime(), differenceInSeconds)
  };

  const calculateDistance = (l1, l2) => {

    let dist = haversineDistance(parseFloat(l1), parseFloat(l2), parseFloat(formData.startLat), parseFloat(formData.startLng))
    console.log("calculateDistance", l1, l2, parseFloat(formData.startLat))
    setFormData(prevState => ({
      ...prevState,
      distanceCovered: dist,
    }))

  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    console.log(lat1, lon1, lat2, lon2)
    const toRad = (x) => (x * Math.PI) / 180;
    const R = 6371; // Earth radius in kilometers

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <div style={styles.container}>

        {isModalOpen && (
          <div style={styles.modal}>
            <div style={styles.modalContent}>
              <h2 style={styles.modalTitle}>Allow Location Access and Start Journey</h2>
              {/* <p style={styles.modalText}>We need your permission to access your location.</p> */}
              <button onClick={() => handleLocationAccess()} style={styles.modalButton}>
                Allow
              </button>
              <button onClick={() => setIsModalOpen(false)} style={styles.modalButton}>
                Deny
              </button>
            </div>
          </div>
        )}
      </div>

      {formData.startLng && (
        <Card sx={{ mt: 4 }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Location Details
            </Typography>
            <Typography variant="body1">
              <strong>Your Current Location </strong>{formData.startLat},{formData.startLng}
            </Typography>
            <Typography variant="body1">
              <strong>Alarm Triggered Location </strong>{alarmData?.site?.latitude},{alarmData?.site?.longitude}
            </Typography>
            <Typography variant="body1">
              <strong>Distance to Cover (in Km) </strong>
              {haversineDistance(parseFloat(alarmData?.site?.latitude), parseFloat(alarmData?.site?.longitude), parseFloat(formData.startLat), parseFloat(formData.startLng))}
            </Typography>
            <Typography variant="body1">
            </Typography>
            {/* <Typography variant="body1">
                <strong>End Latitude:</strong> {formData.startLat}
              </Typography>
              <Typography variant="body1">
                <strong>End Longitude:</strong> {formData.startLng}
              </Typography> */}
          </CardContent>
        </Card>
      )}
      <ThemeProvider theme={theme}>
        <Typography className="title-font" component="h6" variant="h5"> Welcome! Please enter the details to Submit Response</Typography>



        <Typography variant="h6">
          <p>Alarm Title-  <strong>{alarmData?.title}</strong> </p>
          <p>Alarm Description-  <strong>{alarmData?.description}</strong> </p>
          <p>Alarm Responder-  <strong>{alarmData?.user?.firstname}</strong> </p>
          <p>Due Date:-  <strong>{alarmData?.dueDate}</strong></p>
          <p>Due Time:- <strong>{alarmData?.endTime}</strong></p>
        </Typography>

        <Typography variant="h6">
          <p>Client Name:-  <strong>{alarmData?.site?.companyId?.name}</strong> </p>
          <p>Site Name:-  <strong>{alarmData?.site?.name}</strong></p>
          <p>Site Address:- <strong>{alarmData?.site?.address} </strong></p>
        </Typography>




      </ThemeProvider>
      <TextField
        select
        label="Status"
        name="status"
        value={formData.status}
        onChange={handleChange}
        fullWidth
      >

        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="Deferred">Deferred</MenuItem>
        <MenuItem value="Opened">Opened</MenuItem>
      </TextField>
      <TextField
        label="Notes"
        name="notes"
        value={formData.notes}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
      />
      <input
        type="file"
        name="picture"
        accept="image/*"
        onChange={handleChange}
        style={{ display: 'none' }}
        id="picture-upload"
      />
      <label htmlFor="picture-upload">
        <Button variant="contained" component="span" fullWidth>
          Upload Picture
        </Button>
      </label>
      {previews.picture && (
        <Box sx={{ position: 'relative' }}>
          <img src={previews.picture} alt="Preview" style={{ width: '100%', height: 'auto' }} />
          {progress.picture > 0 && progress.picture < 100 && (
            <CircularProgress
              variant="determinate"
              value={progress.picture}
              size={24}
              sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />
          )}
        </Box>
      )}

      <label htmlFor="signature-upload">
        {/* <Button variant="contained" component="span" fullWidth>
          Upload Signature 
        </Button> */}
        <SignaturePad onSave={(dataUrl => setFormData({ ...formData, signature: dataUrl }))} />
      </label>
      {
        submit ? null :
          <Button type="submit" variant="contained" fullWidth onClick={() => handleSave()}>
            {loader ? `Processing...` : `Next`}
          </Button>
      }
      {submit && (

        <Typography variant="body1">
          <strong>Summary</strong>
          <div className="">Distance Covered: {formData.distanceCovered} kms</div>
          <div className="">Time Taken: {formData.timeTaken} min</div>
        </Typography>

      )}
      {submit &&
        <Button variant="contained" color="success" fullWidth onClick={() => handleSubmit()}>
          Submit
        </Button>
      }
    </Box>
  )
}
const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999', // Added z-index to ensure the modal is on top
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    zIndex: '10000', // Added z-index to ensure the content is on top
  },
  modalTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  modalText: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  modalButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    margin: '5px',
  },
  locationInfo: {
    marginTop: '20px',
    fontSize: '16px',
  },
};

export default AlarmResponse    