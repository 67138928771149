import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedAdmin from "../layout/ProtectedAdmin";


// vms Login
import VMS from '../VMS/login/index'
import VmsProfile from '../VMS/profile'
import VmsInduction from '../VMS/induction'
import VmsVisit from '../VMS/visit'
import VmsTaskNote from '../VMS/taskNote'
import VmsUpdateTask from '../VMS/updateTask'
import VmsCheckIn from '../VMS/checkin'
import VmsHistory from '../VMS/history'
import VmsCheckout from '../VMS/checkOut'
import VmsCompCheckout from '../VMS/compCheckOut'
import AlarmResponse from '../alarm/AlarmResponse.jsx'
import NotFound from "../NotFound.jsx";
import Home from "../VMS/Home.jsx";


function PageRoutes() {
  const [siteId, setSiteId] = React.useState("");

  const handleSiteId = (id) => {
    setSiteId(id)
  }
  return (
    <Routes>

      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/:id"
        element={<VMS />}
      />
      <Route
        path="/vms/profile"
        element={
          <ProtectedAdmin>
            <VmsProfile />
          </ProtectedAdmin>
        }
      />
      <Route
        path="/vms/induction"
        element={<ProtectedAdmin><VmsInduction /></ProtectedAdmin>}
      />
      <Route
        path="/vms/visit"
        element={<ProtectedAdmin><VmsVisit /></ProtectedAdmin>}
      />
      <Route
        path="/vms/task_&_Notify"
        element={<ProtectedAdmin><VmsTaskNote /></ProtectedAdmin>}
      />
      <Route
        path="/vms/task_&_Notify"
        element={<ProtectedAdmin><VmsTaskNote /></ProtectedAdmin>}
      />
      <Route
        path="/vms/update_Task/:id"
        element={<ProtectedAdmin><VmsUpdateTask /></ProtectedAdmin>}
      />
      <Route
        path="/vms/CheckIn"
        element={<ProtectedAdmin><VmsCheckIn /></ProtectedAdmin>}
      />
      <Route
        path="/vms/history"
        element={<ProtectedAdmin><VmsHistory /></ProtectedAdmin>}
      />
      <Route
        path="/vms/checkout"
        element={<ProtectedAdmin><VmsCheckout /></ProtectedAdmin>}
      />
      <Route
        path="/vms/complete_checkout"
        element={<ProtectedAdmin><VmsCompCheckout /></ProtectedAdmin>}
      />
      <Route
        path="/alarm-response/:token"
        element={<ProtectedAdmin><AlarmResponse/></ProtectedAdmin>}
      />
      <Route
        path="*"
        element={<NotFound/>}
      />
      <Route
        path="/Submitted"
        element={<NotFound/>}
      />

    </Routes>
  );
}

export default PageRoutes;
