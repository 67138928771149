import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    FormControl, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import VMSheader from './vmsheader';
import './style.css'
import { useNavigate } from "react-router-dom";
import { getAPI } from '../network';
import { formatDate, timeFormat } from '../utils';



export default function TaskNote() {
    const navigateTo = useNavigate();
    const [todayTask, setTodayTask] = useState([])
    const [upcomingTask, setUpcomingTask] = useState([])
    const userId = localStorage.getItem("userId");
    const siteId = localStorage.getItem("siteId");

    useEffect(() => {
        getTodayTask()
        getUpcomingTask()
    }, [])

    const getTodayTask = async () => {

        const data = await getAPI(`/visitor-tasks/today?assignedUser=${userId}&siteId=${siteId}`)
        if (data) {
            setTodayTask(data)
        }
    }
    const getUpcomingTask = async () => {

        const data = await getAPI(`/visitor-tasks/upcoming?assignedUser=${userId}&siteId=${siteId}`)
        if (data) {
            setUpcomingTask(data)
        }
    }

    const handleNavigate = (id) => {
        navigateTo(`/vms/update_Task/${id}`);
    }



    return (
        <Grid sx={{ width: '100%', display: 'flex', justifyContent: "center", }}>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '90%' }}>
                <VMSheader />

                <Typography className='title-font' component="h6" variant="h5" sx={{ margin: '20px', fontWeight: 800, fontFamily: 'Poppins' }}>Tasks & Notifications</Typography>


                <Button variant="outlined" color="error" onClick={() => navigateTo("/vms/checkout")}>Check Out</Button>

                {!todayTask.length ?
                    <Typography component='h6' varient='' className='visit'>There is no Task assigned to you. </Typography>
                    :
                    <Typography component='h6' varient='' className='visit'>Please select Today's task to update status</Typography>
                }
                {
                    todayTask.map((item, i) => (
                        <Typography component="h6" variant="h5" sx={{ marginTop: '15px' }} key={i}>
                            <div className='cards'>
                                <div >
                                    <p className='para'><b>Title</b> : {item?.title}</p>
                                    <p className='para'><b>Description</b> :{item?.description.slice(0,21)}....</p>
                                    <p className='para'><b>Due Date</b> :{formatDate(item?.taskDate)}</p>
                                    <p className='para'><b>Time Due </b>:{item?.timeDue}</p>
                                </div>
                                {
                                    item?.taskClockOutData?.checkOut
                                        ?
                                        <div><Button variant="contained" color="success" onClick={() => { handleNavigate(item._id) }}>{item.status}</Button></div>
                                        :
                                        <div><Button variant="contained" onClick={() => { handleNavigate(item._id) }}>Open</Button></div>

                                }
                            </div>
                        </Typography>
                    ))
                }
                {!upcomingTask.length ?
                    <Typography component='h6' varient='' className='visit'>No Upcoming Tasks at this moment </Typography>
                    :
                    <Typography component='h6' varient='' className='visit'>Please select upcoming Tasks to update status</Typography>
                }
                {
                    upcomingTask.map((item, i) => (
                        <Typography component="h6" variant="h5" sx={{ marginTop: '15px' }} key={i}>
                            <div className='cards'>
                                <div >
                                    <p  className='para'><b>Title</b> : {item?.title}</p>
                                    <p className='para'><b>Description</b> :{item?.description.slice(0,21)}....
                                    </p>
                                    <p className='para'><b>Due Date</b> :{formatDate(item?.taskDate)}</p>
                                    <p className='para'><b>Time Due </b>:{item?.timeDue}</p>
                                </div>
                                <div><Button variant="contained"  onClick={() => { handleNavigate(item._id) }}>Open</Button></div>
                            </div>
                        </Typography>
                    ))
                }



            </Box>
        </Grid>
    )
}

